<template>
    <div>
        <b-container class="product mt-5">
            <b-row class="product-details">
                <b-col md="7">
                    <b-row v-if="product.images.length > 1">
                        <b-col class="d-md-none">
                            <hooper :settings="hooperSettings">
                                <slide v-for="(image, index) in product.images" :key="index">
                                    <img :src="image" alt="">
                                </slide>
                            </hooper>
                        </b-col>
                        <b-col md="2" class="d-none d-md-block">
                            <div class="sticky-top">
                                <nav class="nav-gallery flex column">
                                    <button v-scroll-to="'#image-'+ index" v-for="(image, index) in product.images" :key="index">
                                        <img v-bind:src="image" />
                                    </button>
                                </nav>
                            </div>
                        </b-col>

                        <b-col md="10" class="d-none d-md-block">
                            <div :id="'image-'+ index" v-for="(image, index) in product.images" :key="index">
                                <img v-bind:src="image" />
                            </div>
                        </b-col>
                        
                    </b-row>
                    <div class="sticky-top">
                        <img v-if="product.images.length == 1" v-bind:src="product.images[0]" />
                    </div>
                    <b-row>
                        <b-col role="tablist">
                            <b-card no-body class="mt-3 accordion">
                                <b-card-header header-tag="header" role="tab">
                                    <span block v-b-toggle.accordion-1 class="Collapsible__Button text-uppercase" data-action="toggle-collapsible" aria-expanded="false">90 Day Guarantee<span class="Collapsible__Plus"></span></span>
                                </b-card-header>
                                <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <b-card-text>
                                            <p>We are so confident in the efficacy of our solutions that we offer a hassle-free, ninety day money back guarantee.</p>
                                            <p>If you are not satisfied with your AnteAGE® product for any reason, you are welcome to return your product within ninety days of purchase for a full refund– no questions asked.</p>
                                        </b-card-text>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col md="5">
                    <h4 class="product-title mt-5">{{ product.title }}</h4>
                    <h6 class="product-price">{{ product.price | currency('$', 0) }}</h6>

                    <b-form-spinbutton :id="'spinbutton-' + index" v-model="quantity" min="1" max="100" class="mb-4" inline></b-form-spinbutton>
                   
                    <b-button class="mb-5" @click="buyNow(product.variants[0].id, quantity); $bvModal.hide('modal-' + index)" v-b-toggle.sidebar-backdrop="'modal-' + index" variant="primary" block>Buy it Now</b-button>

                    <p class="product-description" v-html="product.descriptionHtml"></p>
                    <b-button v-scroll-to="'#image-0'" variant="primary">Back to Top</b-button>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import ShopifyClient from '../services/ShopifyClient'
    import config from '../../config.js'
    import Vue2Filters from 'vue2-filters'
    import { Hooper, Slide } from 'hooper'
    import 'hooper/dist/hooper.css'

    export default {
        name: 'Product',
        mixins: [Vue2Filters.mixin],
        components: { Hooper, Slide },
        props: {
            config: Object,
            checkoutId: String,
            checkoutUrl: null,
            cartLineItems: {
                default: []
            },
        },
        data() {
            return {
                selectedVariant: null,
                index: null,
                product: {
                    images: [0]
                },
                quantity: 1,
                hooperSettings: {
                    itemsToShow: 1,
                    centerMode: true
                }
            }
        },
        mounted() {
            this.shopifyClient = new ShopifyClient(config.shopifyDomain, config.shopifyToken)
            // this.selectedVariant = this.product.variants[0]

            // console.log('product handle', config.productHandle)
            if ( config.productHandle ) {
                this.shopifyClient.productByHandleQuery(config.productHandle, product => {
                    console.log('product ', product)
                    this.product = product
                })
            }
        },
        methods: {
            selectVariant(variant, event) {
                event.preventDefault();
                this.selectedVariant = variant
                console.log(this.selectedVariant)
            },
            buyNow(variantId, quantity) {
                // console.log('add to cart fired on collection list', variantId);
                this.shopifyClient.buyNow(variantId, this.checkoutId, quantity, successResponse => {
                    console.log('add to cart success ', variantId, this.checkoutId, successResponse)
                    if (config.googleAnalyticsId) {
                        this.$ga.event('cart', 'added', 'variantId', variantId)
                    }
                    window.location.href = this.checkoutUrl;
                }, errorResponse => {
                    console.log(errorResponse)
                })
            },
            updateSelectedImage(image) {
                this.product.selectedImage = image
            }
        }
    }
</script>