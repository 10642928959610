export default {
    shopifyDomain: "anteage-consumer.myshopify.com",
    shopifyToken: "e69967a9c1fb6ee6e094ef2dbc07a923",
    storeMode: 'grid',
    productHandle: 'anteage-md-travel-size-kit',
    collectionHandle: 'embed',
    localStorageKey: "vue-shopify",
    showUnavailableProducts: true,
    collectionColumns: 3,
    collectionColumnsMobile: 1,
    loadingColor: '#41b883',
    googleAnalyticsId: null,
}