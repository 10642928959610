<template>
    <div class="collection-nav m-3 mb-0"> 
        <b-row>
            <b-col>
                <b-button variant="primary" class="float-right" style="margin-left: 15px;" v-b-toggle.sidebar-backdrop>Cart ({{ cartLineItems.length }})</b-button>
                <p>AnteAGE® and AnteAGE MD® orders will direct you to checkout directly with <a href="https://anteage.com/" target="_blank">AnteAGE®</a>.</p>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: 'CollectionNav',
        props: {
            cartLineItems: {}
        },
        data() {
            return {}
        }
    }
</script>