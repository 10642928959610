<template>
  <div id="app">
    <transition name="fade">
      <Product v-if="storeMode == 'single'" v-on:cart-updated="onCartUpdate" v-on:update-quantity="onUpdateQuantity" :product="product" :cartLineItems="cartLineItems" :checkoutId="checkoutId" :checkoutUrl="checkoutUrl" />
    </transition>
    <transition name="fade">
      <CollectionNav v-if="storeMode == 'grid'" :cartLineItems="cartLineItems" />
    </transition>
    <transition name="fade">
      <CollectionList :config="config" v-if="storeMode == 'grid'" v-on:cart-updated="onCartUpdate" :checkoutId="checkoutId" />
    </transition>
    <transition name="fade">
      <Cart v-if="storeMode == 'grid'" v-on:update-quantity="onUpdateQuantity" v-on:remove-from-cart="onRemoveFromCart" :config="config" :checkoutId="checkoutId" :checkoutUrl="checkoutUrl" :cartLineItems="cartLineItems" :totalPrice="totalPrice" />
    </transition>
    <transition name="fade">
      <div v-if="loadingFull" class="loader-container">
        <p class="loader-text">Loading...</p>
        <b-icon class="loader" icon="circle-fill" animation="throb" font-scale="4" variant="primary"></b-icon>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="!access" class="loader-container">
        <p class="loader-text">Embed Disabled by Admin</p>
        <b-icon class="loader" icon="circle-fill" animation="throb" font-scale="4" variant="danger"></b-icon>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="!domainAccess" class="loader-container">
        <p class="loader-text">The embed widget for this domain has not been registered.</p>
        <b-icon class="loader" icon="circle-fill" animation="throb" font-scale="4" variant="danger"></b-icon>
      </div>
    </transition>
  </div>
</template>

<script>
import CollectionList from './components/CollectionList.vue'
import CollectionNav from './components/CollectionNav.vue'
import Cart from './components/Cart.vue'
import Product from './components/Product.vue'
import config from '../config.js'
import ShopifyClient from './services/ShopifyClient.js'
import { BIcon } from 'bootstrap-vue'
import axios from 'axios';

export default {
  name: 'App',
  components: {
    BIcon,
    CollectionList,
    CollectionNav,
    Cart,
    Product
  },
  props: ['token'],
  data() {
    return {
      loadingFull: false,
      storeMode: config.storeMode,
      config: config,
      checkoutId: null,
      checkoutUrl: null,
      cartLineItems: 0,
      product: null,
      totalPrice: null,
      access: true,
      response: {},
      domainAccess: true
    }
  },
  methods: {
    getEmbedAccess(token) {
        var vm = this;
        let config = {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Access-Control-Allow-Origin" : "*",
            "Access-Control-Allow-Methods" : "GET",
          }
        }
        axios.get('https://anteage-cors.herokuapp.com/https://external.anteage.com/api/embed/access/'+ token +'', config)
        .then(successResponse => {
            console.log("Access Success", successResponse)
            vm.response = successResponse.data;
            if (vm.response.access == 1) {
              vm.access = true;
              vm.loadingFull = false;
            } else {
              vm.access = false;
            }
            this.checkLocation();
        })
        .catch(errorResponse => {
            console.log("Access Error", errorResponse)
            vm.access = errorResponse.data;
        })
    },
    checkLocation() {
      let vm = this;
      let location = window.location.hostname;
      let currentLocation;
      if (location.includes("www.")) {
        currentLocation = location.replace("www.", "");
      } else {
        currentLocation = location
      }
      let domain = vm.response.domain;
      let matchDomain;
      if (domain.includes("www.")) {
        matchDomain = domain.replace("www.", "");
      } else {
        matchDomain = domain
      }
      console.log('domain', matchDomain);
      console.log('location', currentLocation);
      if (matchDomain != currentLocation) {
        vm.access = false;
        vm.domainAccess = false;
      }
    },
    onCartUpdate() {
      this.getCart()
    },
    onRemoveFromCart(lineItemId) {
      this.loadingFull = true
      this.shopifyClient.removeFromCart(lineItemId, this.checkoutId, success => {
        console.log('remove from cart ', success)
        this.loadingFull = false
        this.getCart()
      }, error => {
        console.log("onRemoveFromCart Error", error)
      })
    },
    onUpdateQuantity(lineItemId, variantId, quantity) {
      this.loadingFull = true
      this.shopifyClient.updateQuantity(lineItemId, variantId, quantity, this.checkoutId, success => {
        console.log(success)
        this.loadingFull = false
        this.getCart()
      }, error => {
        console.log("onRemoveFromCart Error", error)
      })
    },
    getCart() {
      this.loadingFull = true
      this.shopifyClient.getCart(this.checkoutId, cart => {
        this.cartCount = cart.count
        this.cartLineItems = cart.lineItems
        this.loadingFull = false

        if ( this.cartCount < 1 ) {
          this.showCart = false
        }

        this.checkoutUrl = cart.checkoutUrl
        this.totalPrice = cart.totalPrice

      }, error => {
        console.log("getCart Error", error);
      })
    },
  },
  onHideLoaderFullScreen() {
    this.loadingFull = false;
  },
  onShowLoaderFullScreen() {
    this.loadingFull = true;
  },
  mounted(){
    // Check Access
    let vm = this;
    let access = this.getEmbedAccess(this.token);
    
    if (vm.access) {
      console.log('access ', vm.access);
    } else {
      console.log('no access ', vm.access)
    }

    
    

    // Initialize 
    this.shopifyClient = new ShopifyClient(config.shopifyDomain, config.shopifyToken);
    this.checkoutId = localStorage.getItem(config.localStorageKey);
    if(!this.checkoutId) {
      this.shopifyClient.createCheckoutId(window.location.href, checkoutId => {
        this.checkoutId = checkoutId
        localStorage.setItem(config.localStorageKey, this.checkoutId);
        this.getCart()
      }, errorResponse => {
          console.log("Could not create checkoutId", errorResponse)
      })
    } else {
      this.getCart()
    }
    if(config.googleAnalyticsId){
      this.$ga.page('/')
    }
    
  }
}
</script>