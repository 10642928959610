<template>
    <div class="cart">
        <b-sidebar id="sidebar-backdrop" title="Cart" backdrop-variant="white" backdrop footer-class="sidebar-footer" right shadow>
            <template v-slot:footer>
                <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-row>
                        <b-col sm="12" class="mt-4">
                            <p>Shipping & taxes calculated at checkout</p>
                        </b-col>
                        <b-col sm="12" class="mt-2 mb-3">
                            <b-button :href="checkoutUrl" @click="clearCart()" variant="primary" block>
                                Checkout - {{ totalPrice | currency('$', 0) }}
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
            </template>
            <b-list-group>
                <b-list-group-item v-for="(lineItem, index) in cartLineItems" :key="lineItem.id">
                    <b-row>
                        <b-col sm="3">
                            <img :src="lineItem.image" alt="">
                        </b-col>
                        <b-col sm="9">
                            <b-row>
                                <h6>{{ lineItem.title }}</h6>
                            </b-row>
                            <b-row class="product-price justify-content-start">
                                {{ lineItem.totalPrice | currency('$', 0) }}
                            </b-row>
                            <b-row class="mt-2 pr-3 justify-content-start">
                                <b-row>
                                    <b-col>
                                        <b-form-spinbutton :id="'spinbutton-' + index" v-model="lineItem.quantity" min="1" max="100" @change="updateQuantity(lineItem, $event)" inline></b-form-spinbutton>
                                        <b-button variant="outline" size="sm" @click="remove(lineItem)">Remove</b-button>
                                    </b-col>
                                </b-row>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-list-group-item>
            </b-list-group>
            
        </b-sidebar>
    </div>
</template>

<script>
    import config from '../../config.js'
    import Vue2Filters from 'vue2-filters'

    export default {
        name: 'Cart',
        mixins: [Vue2Filters.mixin],
        props: {
            config: null,
            checkoutId: null,
            checkoutUrl: null,
            cartLineItems: {
                default: []
            },
            totalPrice: String
        },
        data() {
            return {
                quantity: {}
            }
        },
        methods: {
            clearCart() {
              localStorage.removeItem(config.localStorageKey)  
            },
            updateQuantity(lineItem, $event) {
                console.log('update quantity from cart ', $event);
                let quantity = $event;
                this.$emit('update-quantity', lineItem.id, lineItem.variantId, quantity)
            },
            remove(lineItem){
                this.$emit('remove-from-cart', lineItem.id)
            },
        }
    }
</script>