import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import App from './App.vue'
import VueAnalytics from 'vue-analytics'
import Vue2Filters from 'vue2-filters'
import VueScrollTo from 'vue-scrollto'

import config from '../config'

// Browser support for polyfills
import 'document-register-element/build/document-register-element'

// HTML Element
import vueCustomElement from 'vue-custom-element';

import './scss/app.scss'


Vue.use(BootstrapVue)

Vue.use(BootstrapVueIcons)

Vue.use(Vue2Filters)

Vue.use(VueScrollTo, {
  container: "body",
  duration: 1000,
  easing: "ease-in-out",
  offset: -50,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

Vue.config.productionTip = false
Vue.config.devtools = true;

if(config.googleAnalyticsId){
  Vue.use(VueAnalytics, {
    id: config.googleAnalyticsId
  })
}

// Enable the plugin
Vue.use(vueCustomElement);


// Register your component
Vue.customElement('anteage-widget', App, {
  // Additional Options: https://github.com/karol-f/vue-custom-element#options
});
