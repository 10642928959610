<template>
    <div class="collections">

        <b-container fluid>
            <div class="products" v-if="products.length">
                <b-row class="row-same align-items-stretch">
                    <div v-bind:class="'col-sm-' + columns + ' product-item mb-5'" :id="'item-'+ index" v-for="(product, index) in products" :key="product.id">
                        <div class="product-item-content" v-b-modal="'modal-' + index">
                            <div class="product-image" v-if="product.images.length">
                                <img v-bind:src="product.images[0]" />
                            </div>
                            <div class="product-bottom">
                                <h3 class="product-item-title pt-4" v-if="product.title">{{product.title}}</h3>
                                <div class="product-item-price pb-3" v-if="product.price">${{product.price | currency('', 0) }}</div>
                            </div>
                        </div>
                    </div>
                </b-row>
            </div>
        </b-container>

        <b-modal :id="'modal-'+ index"  size="xl" v-for="(product, index) in products" :key="product.id" static>
            <b-row class="product-details">
                <b-col class="align-self-start" lg="8">
                    <b-row v-if="product.images.length > 1">
                        <b-col class="d-md-none">
                            <hooper :settings="hooperSettings">
                                <slide v-for="(image, index) in product.images" :key="index">
                                    <img :src="image" alt="">
                                </slide>
                            </hooper>
                        </b-col>
                        <b-col md="12" class="d-md-block">
                            <div :id="'image-'+ index" v-for="(image, index) in product.images" :key="index">
                                <img v-bind:src="image" />
                            </div>
                        </b-col>
                    </b-row>
                    <img v-if="product.images.length == 1" v-bind:src="product.images[0]" />
                    <b-row>
                        <b-col role="tablist">
                            <b-card no-body class="mb-1 mt-3 accordion">
                                <b-card-header header-tag="header" role="tab">
                                    <span block v-b-toggle.accordion-1 class="Collapsible__Button text-uppercase" data-action="toggle-collapsible" aria-expanded="false">90 Day Guarantee<span class="Collapsible__Plus"></span></span>
                                </b-card-header>
                                <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <b-card-text>
                                            <p>We are so confident in the efficacy of our solutions that we offer a hassle-free, ninety day money back guarantee.</p>
                                            <p>If you are not satisfied with your AnteAGE MD® product for any reason, you are welcome to return your product within ninety days of purchase for a full refund– no questions asked.</p>
                                        </b-card-text>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="align-self-start" lg="4">
                    <h4 class="product-title">{{ product.title }}</h4>
                    <h6 class="product-price">{{ product.price | currency('$', 0) }}</h6>
                        
                    <b-button class="mb-5" @click="addToCart(product.variants[0].id, $event); $bvModal.hide('modal-' + index)" v-b-toggle.sidebar-backdrop="'modal-' + index" variant="primary" block>Add to Cart</b-button>
                    <p class="product-description" v-html="product.descriptionHtml"></p>
                </b-col>
            </b-row>
        </b-modal>
       
    </div>
</template>

<script>
    import ShopifyClient from '../services/ShopifyClient'
    import config from '../../config.js'
    import Vue2Filters from 'vue2-filters'
    import { Hooper, Slide } from 'hooper'
    import 'hooper/dist/hooper.css';


    export default {
        name: 'CollectionList',
        mixins: [Vue2Filters.mixin],
        components: { Hooper },
        props: {
            config: Object,
            checkoutId: String
        },
        data() {
            return {
                columns: 12 / config.collectionColumns,
                products: [],
                hooperSettings: {
                    itemsToShow: 1,
                    centerMode: true
                }
            }
        },
        mounted() {
            this.shopifyClient = new ShopifyClient(this.config.shopifyDomain, this.config.shopifyToken);

            if ( this.config.collectionHandle ) {
                this.shopifyClient.productsFromCollection(this.config.collectionHandle, products => {
                    console.log(products)
                    this.products = products
                })
            } else {
                this.shopifyClient.allProducts(products => {
                    console.log(products)
                    this.products = products
                })
            }
        },
        methods: {
            selectVariant(variant, event) {
                event.preventDefault();
                this.selectedVariant = variant
                console.log(this.selectedVariant)
            },
            addToCart(variantId) {
                console.log('add to cart fired on collection list', variantId);
                this.shopifyClient.addToCart(variantId, this.checkoutId, successResponse => {
                    console.log('add to cart success ', variantId, this.checkoutId, successResponse)
                    if(config.googleAnalyticsId){
                        this.$ga.event('cart', 'added', 'variantId', variantId)
                    }
                    this.$emit('cart-updated')
                }, errorResponse => {
                    console.log(errorResponse)
                })
            },
        }
    }
</script>